import React from "react";
import Layout from "../components/Layout";
import { Container, Text } from "@chakra-ui/react";
import PageHeading from "../components/PageHeadingSection";
import { useStaticQuery, graphql } from "gatsby";
import AccrediatedBy from "../components/AccrediatedBy";

export default function AboutUs() {
  const queryResult = useStaticQuery(graphql`
    query AboutUsContent {
      prismicAboutUs {
        data {
          content {
            html
          }
        }
      }
    }
  `);

  const htmlContent = queryResult.prismicAboutUs.data.content.html;
  return (
    <Layout>
      <PageHeading title="About Us" />
      <Container maxW={"7xl"} py={5}>
        <Text
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        ></Text>
        <AccrediatedBy />
      </Container>
    </Layout>
  );
}
